import React from 'react'
import PropTypes from 'prop-types'
import { Avatar } from 'antd'

import { Username } from './styled'

import { getShortName } from 'Common/utils'
import { FormattedMessage } from 'react-intl'

const User = props => {
  const { user, onClick } = props
  return (
    <div onClick={onClick}>
      <Avatar size={'small'} icon={'user'} />
      <Username>
        {getShortName(user.name, user.surname)} •{' '}
        <FormattedMessage
          id={'app.header.storeId'}
          values={{ storeId: user.storeId }}
        />
      </Username>
    </div>
  )
}
User.propTypes = {
  onClick: PropTypes.func,
  user: PropTypes.object,
}
export default User
