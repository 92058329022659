// USER
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'
export const UPDATE_STORE_ID = 'UPDATE_STORE_ID'

export const GET_STORES = 'GET_STORES'

// LOCKER
export const GET_LOCKERS = 'GET_LOCKERS'
export const GET_LOCKER_STATUS = 'GET_LOCKER_STATUS'
export const RESET_LOCKER_STATUS = 'RESET_LOCKER_STATUS'
export const UPDATE_LOCKER = 'UPDATE_LOCKER'

export const IMPORT_ORDER = 'IMPORT_ORDER'
export const UPDATE_LOCKER_SIZE = 'UPDATE_LOCKER_SIZE'

export const GET_PARCELS = 'GET_PARCELS'
export const GET_PARCEL = 'GET_PARCEL'
export const CREATE_PARCEL = 'CREATE_PARCEL'
export const CANCEL_PARCEL = 'CANCEL_PARCEL'
export const RESET_CURRENT_PARCEL = 'RESET_CURRENT_PARCEL'

// TOUR
export const INIT_TOURS = 'INIT_TOURS'
export const SET_TOUR = 'SET_TOUR'
export const RESET_TOUR = 'RESET_TOUR'
export const RESET_ALL_TOURS = 'RESET_ALL_TOURS'
export const SET_TOUR_DATA = 'SET_TOUR_DATA'
export const RESET_TOUR_DATA = 'RESET_TOUR_DATA'
