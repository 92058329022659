const env = process.env.REACT_APP_ENV
const redirectAfterLogin = encodeURIComponent(window.location.href)
const envConfig = {
  dev: {
    LOGIN_URL: `https://test-lmit-srmlocker-itlm-ping-47rh53ma5a-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'http://localhost:8080',
  },
  test: {
    LOGIN_URL: `https://test-lmit-srmlocker-itlm-ping-47rh53ma5a-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://test-srmlocker-api-47rh53ma5a-ew.a.run.app',
  },
  prod: {
    LOGIN_URL: `https://prod-lmit-srmlocker-itlm-ping-47rh53ma5a-ew.a.run.app/sso/auth/?sso&redirectAfterLogin=${redirectAfterLogin}`,
    API_URL: 'https://prod-srmlocker-api-47rh53ma5a-ew.a.run.app',
  },
}

export default envConfig[env]
