import API from './axiosConfig'

export default {
  store: {
    list: () => API.get('/stores'),
  },
  user: {
    authenticate: token =>
      API.get('/user', { headers: { Authorization: `Bearer ${token}` } }),
  },
  locker: {
    list: storeId => API.get('/lockers', { params: { storeId } }),
    status: lockerId => API.get(`/locker/${lockerId}/status`),
  },

  order: {
    import: (storeId, orderNumber) =>
      API.get('/order/import', { params: { storeId, orderNumber } }),
  },

  parcel: {
    list: (storeId, type) => API.get('/parcels', { params: { storeId, type } }),
    get: parcelId => API.get(`/parcel/${parcelId}`),
    cancel: parcelId => API.get(`/parcel/${parcelId}/cancel`),
    create: body => API.post('/parcel', body),
  },
}
