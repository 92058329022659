import styled from 'styled-components'
import { Drawer, Statistic } from 'antd'

import Button from 'Components/Button'
import { colors } from 'Common/constants'

export const LockerStatusDrawer = styled(Drawer)`
  @media (min-width: 1024px) {
    .ant-drawer-body {
      width: 848px;
      margin: 0 auto;
    }
  }
`
export const LockerStatusButton = styled(Button)`
  width: auto;
  position: fixed;
  bottom: 16px;
  left: 16px;
`
export const LockerStatusAvailability = styled(Statistic)`
  width: ${props => `calc(100% / ${props.length})`};
  display: inline-block;
  text-align: center;
  padding: 16px 0;
  .ant-statistic-content {
    color: ${props => (props.value > 0 ? 'inherit' : colors.error)};
  }
`
