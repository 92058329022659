import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Button, Empty, Icon, Input, Table } from 'antd'

import Loader from 'Components/Loader'

import { colors, DATE_FORMAT } from 'Common/constants'

const ParcelTable = props => {
  const { intl, isLoading, data } = props
  const columns = [
    {
      title: intl.formatMessage({ id: 'parcelTable.column.orderNumber' }),
      dataIndex: 'fullOrderNumber',
      width: 100,
      filterDropdown: props => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props
        return (
          <>
            <Input
              placeholder={intl.formatMessage({
                id: 'parcelTable.placeholder.fullOrderNumber',
              })}
              value={selectedKeys[0]}
              onChange={e =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={confirm}
              style={{ margin: 12, width: 150 }}
            />
            <div className={'ant-table-filter-dropdown-btns'}>
              <span
                className={'ant-table-filter-dropdown-link confirm'}
                onClick={confirm}>
                <FormattedMessage id={'parcelTable.filter.submit'} />
              </span>
              <span
                className={'ant-table-filter-dropdown-link clear'}
                onClick={clearFilters}>
                <FormattedMessage id={'parcelTable.filter.reset'} />
              </span>
            </div>
          </>
        )
      },
      filterIcon: filtered => (
        <Icon
          type={'search'}
          style={{ color: filtered ? colors.main : undefined }}
        />
      ),
      onFilter: (value, row) => row.fullOrderNumber.toString().includes(value),
    },
    {
      title: intl.formatMessage({ id: 'parcelTable.column.orderDate' }),
      dataIndex: 'orderDate',
      width: 100,
      render: orderDate => moment(orderDate).format(DATE_FORMAT),
      sorter: (a, b) => a.orderDate.localeCompare(b.orderDate),
    },
    {
      title: intl.formatMessage({ id: 'parcelTable.column.parcelId' }),
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      key: 'action',
      width: 48,
      render: row => (
        <Link to={`/parcel/${row.id}`}>
          <Button icon={'eye'} type={'primary'} />
        </Link>
      ),
    },
  ]

  return (
    <Table
      size={'small'}
      dataSource={data}
      columns={columns}
      rowKey={row => row.id}
      locale={{ emptyText: <Empty description={false} /> }}
      loading={{ spinning: isLoading, indicator: <Loader height={'100%'} /> }}
    />
  )
}
ParcelTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
}
export default injectIntl(ParcelTable)
