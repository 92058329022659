import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { injectIntl } from 'react-intl'
import { Switch, Route } from 'react-router-dom'
import { Result } from 'antd'
import Tour from 'reactour'
import { connect } from 'react-redux'

import { getStores } from 'Redux/actions/app-actions'
import { updateStoreId } from 'Redux/actions/user-actions'
import { initTours, setTour, resetTour } from 'Redux/actions/tour-actions'

import Auth from 'Containers/Auth'
import Home from 'Containers/Home'
import NewParcel from 'Containers/NewParcel'
import Parcel from 'Containers/Parcel'
import Page404 from 'Containers/404'
import Header from 'Components/Header'
import Footer from 'Components/Footer'
import StoreModal from 'Components/StoreModal'
import FeedbackButton from 'Components/FeedbackButton'
import { WhiteLayout, PaddedContent } from 'Common/styled'

import { colors } from 'Common/constants'

class App extends Component {
  static propTypes = {
    allTours: PropTypes.object,
    currentTour: PropTypes.string,
    initTours: PropTypes.func,
    resetTour: PropTypes.func,
    setTour: PropTypes.func,
    tourSteps: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.object,
  }
  state = {
    isStoreModalVisible: false,
  }

  componentDidMount() {
    const { initTours, user } = this.props
    initTours()

    this.tangram = new window.Tangram({
      productId: '95a7e078-9c10-4c5d-9b00-811eb5dc8795', // PUT YOUR PRODUCT ID HERE.
      userLdap: user.LDAP,
      buttonOptions: {
        // This options is needed so you don't have two buttons.
        noShowOnStartup: true,
      },
    })
  }
  componentDidUpdate(prevProps, prevState) {
    const { user, getStores } = this.props
    if (isEmpty(prevProps.user) && (user.isAdmin || user.isCustomerCare)) {
      getStores()
      this.openStoreModal()
    }
  }

  updateStoreId = storeId => {
    const { updateStoreId } = this.props
    updateStoreId(storeId)
    this.closeStoreModal()
  }

  openStoreModal = () => this.setState({ isStoreModalVisible: true })
  closeStoreModal = () => this.setState({ isStoreModalVisible: false })

  renderUserIsNoEnabled() {
    const { intl } = this.props
    return (
      <Result
        status={'403'}
        title={intl.formatMessage({ id: 'app.user.isNotEnabled' })}
      />
    )
  }
  renderUserHasNoLockers() {
    const { intl } = this.props
    return (
      <Result
        status={'404'}
        title={intl.formatMessage({ id: 'app.user.hasNoLockers' })}
      />
    )
  }
  render() {
    const { isStoreModalVisible } = this.state
    const {
      user,
      stores,
      tourSteps,
      currentTour,
      allTours,
      resetTour,
      setTour,
    } = this.props
    const isTourOpen = currentTour in allTours && !allTours[currentTour]
    const { isEnabled, isAdmin, isCustomerCare, hasLockers } = user
    return (
      <WhiteLayout>
        <Header
          user={user}
          changeStore={this.openStoreModal}
          hasTour={currentTour ? true : false}
          resetTour={() => resetTour(currentTour)}
        />
        <Auth>
          <PaddedContent>
            <Switch>
              {!isEnabled ? (
                <Route render={() => this.renderUserIsNoEnabled()} />
              ) : null}
              {!isAdmin && !isCustomerCare && !hasLockers ? (
                <Route render={() => this.renderUserHasNoLockers()} />
              ) : null}
              <Route component={Home} exact path={'/'} />
              <Route component={NewParcel} exact path={'/new'} />
              <Route component={Parcel} exact path={'/parcel/:parcelId'} />
              <Route component={Page404} />
            </Switch>
          </PaddedContent>
        </Auth>
        <Footer storeId={user.storeId} />
        <StoreModal
          stores={stores}
          isVisible={isStoreModalVisible}
          onCloseModal={this.closeStoreModal}
          onSelectStore={this.updateStoreId}
        />
        <FeedbackButton onClick={() => this.tangram.open()} />
        <Tour
          accentColor={colors.main}
          steps={tourSteps}
          startAt={0}
          isOpen={isTourOpen || false}
          onRequestClose={() => setTour(currentTour)}
        />
      </WhiteLayout>
    )
  }
}
const mapStateToProps = state => ({
  user: state.user,
  stores: state.app.stores,
  tourSteps: state.tour.steps,
  currentTour: state.tour.current,
  allTours: state.tour.allTours,
})
const mapDispatchToProps = {
  getStores,
  updateStoreId,
  initTours,
  setTour,
  resetTour,
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(App))
