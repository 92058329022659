import { combineReducers } from 'redux'
import app from './app-reducer'
import user from './user-reducer'
import locker from './locker-reducer'
import parcel from './parcel-reducer'
import tour from './tour-reducer'

const combinedReducers = combineReducers({
  app,
  user,
  locker,
  parcel,
  tour,
})

export default combinedReducers
