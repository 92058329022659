import styled from 'styled-components'
import { Radio } from 'antd'

export const RadioGroup = styled(Radio.Group)`
  width: 100%;
`
export const RadioButton = styled(Radio.Button)`
  width: ${props => `calc(100% / ${props.length})`};
  text-align: center;
`
