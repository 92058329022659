import styled from 'styled-components'
import { Layout, Button, Tabs, Select } from 'antd'

import { colors } from './constants'

export const WhiteLayout = styled(Layout)`
  background: ${colors.white};
`
export const PaddedContent = styled(Layout.Content)`
  padding: 16px;
  min-height: calc(100vh - 112px);
  display: grid;
  gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, min-content));
  @media (min-width: 1024px) {
    width: 800px;
    margin: 0 auto;
  }
`
export const UppercaseButton = styled(Button)`
  text-transform: uppercase;
  width: 100%;
`
export const PageTitle = styled.h1`
  color: ${colors.main};
  font-size: 32px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
`
export const SmallTitle = styled.h3`
  color: ${colors.grey4};
  text-transform: uppercase;
`
export const StyledSelect = styled(Select)`
  width: 100%;
  display: block;
`
export const ActionsRow = styled.div`
  padding: 16px 0;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
`

export const HomeTabs = styled(Tabs)`
  .ant-tabs-nav.ant-tabs-nav-animated {
    width: 100%;
  }
  .ant-tabs-tab {
    width: ${props => `calc(100% / ${props.length})`};
    margin: 0;
    text-align: center;
    text-transform: uppercase;
  }
`
