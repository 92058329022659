import store from '../store'
import {
  GET_LOCKERS,
  GET_LOCKER_STATUS,
  RESET_LOCKER_STATUS,
  UPDATE_LOCKER,
} from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const getLockers = () => async dispatch => {
  const { storeId } = store.getState().user
  try {
    const res = await api.locker.list(storeId)
    const { lockers } = res.data
    if (lockers.length > 0) {
      dispatch(getLockerStatus(lockers[0].Id))
    } else {
      dispatch(resetLockerStatus())
    }
    return dispatch({ type: GET_LOCKERS, payload: lockers })
  } catch (error) {
    return handleServerError(error)
  }
}
export const getLockerStatus = lockerId => async dispatch => {
  try {
    const res = await api.locker.status(lockerId)
    dispatch(updateSelectedLocker(lockerId))
    return dispatch({ type: GET_LOCKER_STATUS, payload: res.data.status })
  } catch (error) {
    return handleServerError
  }
}
export const resetLockerStatus = () => {
  return { type: RESET_LOCKER_STATUS }
}
export const updateSelectedLocker = lockerId => {
  const lockers = store.getState().locker.list
  const locker = lockers.find(l => l.Id === lockerId)
  return {
    type: UPDATE_LOCKER,
    payload: { lockerId, lockerExternalId: locker.externalId },
  }
}
