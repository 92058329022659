import {
  IMPORT_ORDER,
  UPDATE_LOCKER_SIZE,
  UPDATE_LOCKER,
  GET_PARCELS,
  GET_PARCEL,
  RESET_CURRENT_PARCEL,
} from '../action-types'

const initialState = {
  current: {},
  list: [],
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case IMPORT_ORDER:
    case GET_PARCEL:
      return { ...state, current: { ...state.current, ...payload } }
    case UPDATE_LOCKER:
      return { ...state, current: { ...state.current, ...payload } }
    case UPDATE_LOCKER_SIZE:
      return { ...state, current: { ...state.current, lockerSize: payload } }
    case GET_PARCELS:
      return { ...state, list: payload }
    case RESET_CURRENT_PARCEL:
      return { ...state, current: initialState.current }
    default:
      return state
  }
}
