import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import moment from 'moment'

import Loader from 'Components/Loader'
import {
  LockerStatusDrawer,
  LockerStatusAvailability,
  LockerStatusButton,
} from './styled'
import { StyledSelect } from 'Common/styled'

const { Option } = StyledSelect

const LockerStatus = props => {
  const {
    intl,
    isLoading,
    isDrawerVisible,
    openDrawer,
    closeDrawer,
    lockers,
    lockerStatus,
    selectedLocker,
    changeLocker,
  } = props
  return (
    <>
      <LockerStatusButton ghost onClick={openDrawer}>
        <FormattedMessage id={'home.button.lockerStatus'} />
      </LockerStatusButton>
      <LockerStatusDrawer
        title={intl.formatMessage({ id: 'home.button.lockerStatus' })}
        placement={'bottom'}
        height={'auto'}
        visible={isDrawerVisible}
        onClose={closeDrawer}>
        {isLoading ? (
          <Loader height={'100px'} />
        ) : (
          <>
            <StyledSelect
              size={'large'}
              value={selectedLocker}
              onChange={changeLocker}>
              {lockers.map(locker => (
                <Option key={locker.Id} value={locker.Id}>
                  {locker.name}
                </Option>
              ))}
            </StyledSelect>
            {lockerStatus.map(status => (
              <LockerStatusAvailability
                key={status.size}
                title={status.size}
                value={status.availability}
                length={lockerStatus.length}
              />
            ))}
            <small>{`Ultimo aggiornamento: ${moment(
              lockerStatus[0]?.lastUpdate
            ).format('DD/MM/YYYY HH:mm')}`}</small>
          </>
        )}
      </LockerStatusDrawer>
    </>
  )
}
LockerStatus.propTypes = {
  changeLocker: PropTypes.func,
  closeDrawer: PropTypes.func,
  isDrawerVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  lockers: PropTypes.arrayOf(PropTypes.object),
  lockerStatus: PropTypes.arrayOf(PropTypes.object),
  openDrawer: PropTypes.func,
  selectedLocker: PropTypes.number,
}
export default injectIntl(LockerStatus)
