import React from 'react'
import PropTypes from 'prop-types'

import { RadioGroup, RadioButton } from './styled'

const LockerStatusRadio = props => {
  const { data, changeLockerSize } = props
  return (
    <RadioGroup
      buttonStyle={'solid'}
      size={'large'}
      onChange={e => changeLockerSize(e.target.value)}>
      {data.map(status => (
        <RadioButton
          key={status.size}
          value={status.size}
          length={data.length}
          disabled={!status.availability}>
          {`${status.size} (${status.availability})`}
        </RadioButton>
      ))}
    </RadioGroup>
  )
}
LockerStatusRadio.propTypes = {
  changeLockerSize: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
}
export default LockerStatusRadio
