import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'

import Button from 'Components/Button'
import { ImportWrapper } from './styled'
import { injectIntl, FormattedMessage } from 'react-intl'

const ImportOrder = props => {
  const { intl, isLoading, importOrder } = props
  return (
    <ImportWrapper>
      <Input
        id={'order-number'}
        size={'large'}
        maxLength={10}
        placeholder={intl.formatMessage({ id: 'newParcel.placeholder.import' })}
        onPressEnter={importOrder}
        disabled={isLoading}
      />
      <Button icon={'import'} loading={isLoading} onClick={importOrder}>
        <FormattedMessage id={'newParcel.button.import'} />
      </Button>
    </ImportWrapper>
  )
}
ImportOrder.propTypes = {
  importOrder: PropTypes.func,
  isLoading: PropTypes.bool,
}
export default injectIntl(ImportOrder)
