export const DATE_FORMAT = 'DD-MM-YYYY'

const PARCEL_STATUS_CREATED = 'created'
const PARCEL_STATUS_PREPARED = 'prepared'
export const PARCEL_STATUS_INTRANSIT = 'in_transit'
const PARCEL_STATUS_STORED = 'stored'

const PARCEL_STATUS_EXPIRED = 'expired'

const PARCEL_STATUS_RETURNEDTOSENDER = 'returnedToSender'
const PARCEL_STATUS_RETURNEDTOAGENCY = 'retuned_to_agency'
const PARCEL_STATUS_DELIVERED = 'delivered'
export const PARCEL_STATUS_CANCELLED = 'cancelled'

export const PARCEL_LIST_TYPE_ACTIVE = 'active'
export const PARCEL_LIST_TYPE_EXPIRED = 'expired'
export const PARCEL_LIST_TYPE_ARCHIVE = 'archive'

export const parcelListTypes = {
  [PARCEL_LIST_TYPE_ACTIVE]: [
    PARCEL_STATUS_CREATED,
    PARCEL_STATUS_PREPARED,
    PARCEL_STATUS_INTRANSIT,
    PARCEL_STATUS_STORED,
  ],
  [PARCEL_LIST_TYPE_EXPIRED]: [PARCEL_STATUS_EXPIRED],
  [PARCEL_LIST_TYPE_ARCHIVE]: [
    PARCEL_STATUS_RETURNEDTOSENDER,
    PARCEL_STATUS_RETURNEDTOAGENCY,
    PARCEL_STATUS_DELIVERED,
    PARCEL_STATUS_CANCELLED,
  ],
}
