import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { getParcels } from 'Redux/actions/parcel-actions'
import { getLockers, getLockerStatus } from 'Redux/actions/locker-actions'

import Button from 'Components/Button'
import ParcelTable from 'Components/ParcelTable'
import LockerStatus from 'Components/LockerStatus'
import { HomeTabs } from 'Common/styled'

import { PARCEL_LIST_TYPE_ACTIVE, parcelListTypes } from 'Common/constants'

class Home extends Component {
  state = {
    isTableLoading: false,
    isDrawerVisible: false,
    isDrawerLoading: false,
  }
  static propTypes = {
    getLockers: PropTypes.func,
    getLockerStatus: PropTypes.func,
    getParcels: PropTypes.func,
    isCustomerCare: PropTypes.bool,
    lockers: PropTypes.arrayOf(PropTypes.object),
    lockerStatus: PropTypes.arrayOf(PropTypes.object),
    parcels: PropTypes.arrayOf(PropTypes.object),
    selectedLocker: PropTypes.number,
  }

  componentDidMount() {
    this.getParcels(PARCEL_LIST_TYPE_ACTIVE)
  }

  componentDidUpdate(prevProps) {
    const { storeId } = this.props
    if (prevProps.storeId !== storeId) this.getParcels(PARCEL_LIST_TYPE_ACTIVE)
  }

  getParcels = async type => {
    const { getParcels } = this.props
    this.setState({ isTableLoading: true })
    await getParcels(type)
    this.setState({ isTableLoading: false })
  }

  getLockers = async () => {
    const { getLockers } = this.props
    this.setState({ isDrawerLoading: true })
    await getLockers()
    this.setState({ isDrawerLoading: false })
  }

  getLockerStatus = async lockerId => {
    const { getLockerStatus } = this.props
    this.setState({ isDrawerLoading: true })
    await getLockerStatus(lockerId)
    this.setState({ isDrawerLoading: false })
  }

  openDrawer = () => {
    this.setState({ isDrawerVisible: true })
    this.getLockers()
  }
  closeDrawer = () => this.setState({ isDrawerVisible: false })

  render() {
    const { isTableLoading, isDrawerVisible, isDrawerLoading } = this.state
    const {
      intl,
      isCustomerCare,
      parcels,
      lockers,
      lockerStatus,
      selectedLocker,
    } = this.props
    return (
      <>
        {!isCustomerCare ? <Link to={'/new'}>
          <Button icon={'plus'} style={{ width: 'auto' }}>
            <FormattedMessage id={'home.button.newParcel'} />
          </Button>
        </Link> : null}
        <HomeTabs
          defaultActiveKey={PARCEL_LIST_TYPE_ACTIVE}
          animated={false}
          length={3}
          onChange={key => this.getParcels(key)}>
          {Object.keys(parcelListTypes).map(type => (
            <HomeTabs.TabPane
              tab={intl.formatMessage({ id: `home.tab.${type}` })}
              key={type}>
              <ParcelTable isLoading={isTableLoading} data={parcels} />
            </HomeTabs.TabPane>
          ))}
        </HomeTabs>
        <LockerStatus
          isLoading={isDrawerLoading}
          isDrawerVisible={isDrawerVisible}
          openDrawer={this.openDrawer}
          closeDrawer={this.closeDrawer}
          lockers={lockers}
          lockerStatus={lockerStatus}
          changeLocker={this.getLockerStatus}
          selectedLocker={selectedLocker}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  isCustomerCare: state.user.isCustomerCare,
  storeId: state.user.storeId,
  parcels: state.parcel.list,
  lockers: state.locker.list,
  lockerStatus: state.locker.status,
  selectedLocker: state.locker.selected,
})
const mapDispatchToProps = {
  getParcels,
  getLockers,
  getLockerStatus,
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Home))
