import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { getLockers, getLockerStatus } from 'Redux/actions/locker-actions'
import {
  importOrder,
  updateLockerSize,
  createParcel,
  resetCurrentParcel,
} from 'Redux/actions/parcel-actions'

import Button from 'Components/Button'
import ImportOrder from 'Components/ImportOrder'
import CustomerCard from 'Components/CustomerCard'
import LockerStatusRadio from 'Components/LockerStatusRadio'
import { ActionsRow, PageTitle, SmallTitle, StyledSelect } from 'Common/styled'

const { Option } = StyledSelect

class NewParcel extends Component {
  state = {
    isLockerLoading: false,
    isImportLoading: false,
    isSubmitLoading: false,
  }
  static propTypes = {
    getLockers: PropTypes.func,
    getLockerStatus: PropTypes.func,
    importOrder: PropTypes.func,
    updateLockerSize: PropTypes.func,
  }

  componentDidMount() {
    this.getLockers()
  }

  componentDidUpdate(prevProps) {
    const { storeId } = this.props
    if (prevProps.storeId !== storeId) this.getLockers()
  }

  componentWillUnmount() {
    const { resetCurrentParcel } = this.props
    resetCurrentParcel()
  }

  getLockers = async () => {
    const { getLockers } = this.props
    this.setState({ isLockerLoading: true })
    await getLockers()
    this.setState({ isLockerLoading: false })
  }

  getLockerStatus = async lockerId => {
    const { getLockerStatus } = this.props
    this.setState({ isLockerLoading: true })
    await getLockerStatus(lockerId)
    this.setState({ isLockerLoading: false })
  }

  importOrder = async () => {
    const { importOrder } = this.props
    const orderNumber = document.getElementById('order-number').value

    if (orderNumber) {
      this.setState({ isImportLoading: true })
      await importOrder(orderNumber)

      this.setState({ isImportLoading: false })
    }
  }

  createParcel = async () => {
    const { history, createParcel } = this.props
    this.setState({ isSubmitLoading: true })
    const parcelId = await createParcel()
    if (parcelId) {
      history.push(`/parcel/${parcelId}`)
    } else {
      this.setState({ isSubmitLoading: false })
    }
  }

  render() {
    const { isLockerLoading, isImportLoading, isSubmitLoading } = this.state
    const {
      currentParcel,
      lockers,
      lockerStatus,
      selectedLocker,
      updateLockerSize,
    } = this.props

    const isSubmitDisabled = ![
      'customer',
      'lockerId',
      'lockerExternalId',
      'lockerSize',
      'orderId',
      'fullOrderNumber',
    ].every(key => currentParcel.hasOwnProperty(key))

    return (
      <>
        <PageTitle>
          <FormattedMessage id={'newParcel.title'} />
        </PageTitle>
        <SmallTitle>
          <FormattedMessage id={'newParcel.title.order'} />
        </SmallTitle>
        <ImportOrder
          isLoading={isImportLoading}
          importOrder={this.importOrder}
        />
        {currentParcel.customer ? (
          <CustomerCard data={currentParcel.customer} />
        ) : null}
        <SmallTitle>
          <FormattedMessage id={'newParcel.title.locker'} />
        </SmallTitle>
        <StyledSelect
          size={'large'}
          value={selectedLocker}
          disabled={isLockerLoading}
          onChange={this.getLockerStatus}>
          {lockers.map(locker => (
            <Option key={locker.Id} value={locker.Id}>
              {locker.name}
            </Option>
          ))}
        </StyledSelect>
        <LockerStatusRadio
          data={lockerStatus}
          changeLockerSize={updateLockerSize}
        />
        <ActionsRow>
          <Link to={'/'}>
            <Button ghost>
              <FormattedMessage id={'newParcel.button.cancel'} />
            </Button>
          </Link>
          <Button
            loading={isSubmitLoading}
            disabled={isSubmitDisabled}
            onClick={this.createParcel}>
            <FormattedMessage id={'newParcel.button.submit'} />
          </Button>
        </ActionsRow>
      </>
    )
  }
}

const mapStateToProps = state => ({
  storeId: state.user.storeId,
  lockers: state.locker.list,
  lockerStatus: state.locker.status,
  selectedLocker: state.locker.selected,
  currentParcel: state.parcel.current,
})
const mapDispatchToProps = {
  getLockers,
  getLockerStatus,
  importOrder,
  updateLockerSize,
  createParcel,
  resetCurrentParcel,
}
export default connect(mapStateToProps, mapDispatchToProps)(NewParcel)
