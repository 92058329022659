import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { Card } from 'antd'

import { CardTitle, HighlightText, Text } from './styled'

const CustomerCard = props => {
  const { data } = props
  const { firstName, lastName, email, phoneNumber } = data

  return (
    <Card>
      <CardTitle>
        <FormattedMessage id={'customerCard.title'} />
      </CardTitle>
      <HighlightText>{`${firstName} ${lastName}`}</HighlightText>
      <Text>{email}</Text>
      <Text>{phoneNumber}</Text>
    </Card>
  )
}
CustomerCard.propTypes = {
  data: PropTypes.object,
}
export default CustomerCard
