import {
  GET_LOCKERS,
  GET_LOCKER_STATUS,
  RESET_LOCKER_STATUS,
  UPDATE_LOCKER,
} from '../action-types'

export const initialState = {
  list: [],
  status: [],
  selected: undefined,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_LOCKERS:
      return { ...state, list: payload }
    case GET_LOCKER_STATUS:
      return { ...state, status: payload }
    case RESET_LOCKER_STATUS:
      return {
        ...state,
        status: initialState.status,
        selected: initialState.selected,
      }
    case UPDATE_LOCKER:
      return { ...state, selected: payload.lockerId }
    default:
      return state
  }
}
