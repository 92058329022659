import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Descriptions } from 'antd'
import Barcode from 'react-barcode'
import { connect } from 'react-redux'

import {
  getParcel,
  cancelParcel,
  resetCurrentParcel,
} from 'Redux/actions/parcel-actions'

import Loader from 'Components/Loader'
import Button from 'Components/Button'
import CustomerCard from 'Components/CustomerCard'
import { ActionsRow, PageTitle, SmallTitle } from 'Common/styled'

import {
  DATE_FORMAT,
  PARCEL_STATUS_INTRANSIT,
  PARCEL_STATUS_CANCELLED,
} from 'Common/constants'

const { Item } = Descriptions

class Parcel extends Component {
  state = {
    isLoading: false,
  }
  static propTypes = {
    cancelParcel: PropTypes.func,
    getParcel: PropTypes.func,
    parcel: PropTypes.object,
    resetCurrentParcel: PropTypes.func,
  }

  componentDidMount() {
    this.getParcel()
  }

  componentWillUnmount() {
    const { resetCurrentParcel } = this.props
    resetCurrentParcel()
  }

  getParcel = async () => {
    const { match, getParcel } = this.props
    const parcelId = match.params.parcelId
    this.setState({ isLoading: true })
    await getParcel(parcelId)
    this.setState({ isLoading: false })
  }

  cancelParcel = async parcelId => {
    const { cancelParcel } = this.props
    this.setState({ isLoading: true })
    await cancelParcel(parcelId)
    this.getParcel()
  }

  render() {
    const { isLoading } = this.state
    const { intl, isCustomerCare, parcel } = this.props
    const {
      order_fullNumber,
      order_orderDate,
      order_status,
      id,
      parcel_status,
      parcel_dropoffCode,
      customer,
      locker_name,
      parcel_lockerSize,
    } = parcel

    if (isLoading) return <Loader />
    return (
      <>
        <PageTitle>
          <FormattedMessage id={'parcel.title'} />
        </PageTitle>
        <SmallTitle>
          <FormattedMessage id={'parcel.title.order'} />
        </SmallTitle>
        <Descriptions bordered size={'small'} column={1}>
          <Item label={intl.formatMessage({ id: 'parcel.label.orderNumber' })}>
            {order_fullNumber}
          </Item>
          <Item label={intl.formatMessage({ id: 'parcel.label.orderDate' })}>
            {moment(order_orderDate).format(DATE_FORMAT)}
          </Item>
          <Item label={intl.formatMessage({ id: 'parcel.label.orderStatus' })}>
            {order_status ? (
              <FormattedMessage id={`order.status.${order_status}`} />
            ) : null}
          </Item>
        </Descriptions>
        <SmallTitle>
          <FormattedMessage id={'parcel.title.parcel'} />
        </SmallTitle>
        <Descriptions bordered size={'small'} column={1}>
          <Item label={intl.formatMessage({ id: 'parcel.label.parcelId' })}>
            {id}
          </Item>
          <Item label={intl.formatMessage({ id: 'parcel.label.parcelStatus' })}>
            {parcel_status ? (
              <FormattedMessage id={`parcel.status.${parcel_status}`} />
            ) : null}
          </Item>
          {parcel_status !== PARCEL_STATUS_CANCELLED ? (
            <Item
              label={intl.formatMessage({ id: 'parcel.label.dropoffCode' })}>
              {/* {parcel_dropoffCode} */}
              <Barcode
                value={parcel_dropoffCode || 'dropoffCode'}
                width={1.5}
                font={'sans-serif'}
                fontSize={15}
              />
            </Item>
          ) : null}
        </Descriptions>
        <CustomerCard data={customer || {}} />
        <SmallTitle>
          <FormattedMessage id={'parcel.title.locker'} />
        </SmallTitle>
        <Descriptions bordered size={'small'} column={1}>
          <Item label={intl.formatMessage({ id: 'parcel.label.lockerName' })}>
            {locker_name}
          </Item>
          <Item label={intl.formatMessage({ id: 'parcel.label.lockerSize' })}>
            {parcel_lockerSize}
          </Item>
        </Descriptions>
        <ActionsRow>
          <Link to={'/'}>
            <Button ghost>
              <FormattedMessage id={'parcel.button.back'} />
            </Button>
          </Link>
          {parcel_status === PARCEL_STATUS_INTRANSIT && !isCustomerCare ? (
            <Button onClick={() => this.cancelParcel(id)}>
              <FormattedMessage id={'parcel.button.cancelParcel'} />
            </Button>
          ) : (
            <span />
          )}
        </ActionsRow>
      </>
    )
  }
}

const mapStateToProps = state => ({
  isCustomerCare: state.user.isCustomerCare,
  parcel: state.parcel.current,
})
const mapDispatchToProps = {
  getParcel,
  cancelParcel,
  resetCurrentParcel,
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Parcel))
