import store from '../store'
import {
  CANCEL_PARCEL,
  CREATE_PARCEL,
  GET_PARCEL,
  GET_PARCELS,
  IMPORT_ORDER,
  RESET_CURRENT_PARCEL,
  UPDATE_LOCKER_SIZE,
} from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const importOrder = orderNumber => async dispatch => {
  const { storeId } = store.getState().user
  try {
    const res = await api.order.import(storeId, orderNumber)
    return dispatch({ type: IMPORT_ORDER, payload: res.data.order })
  } catch (error) {
    return handleServerError(error)
  }
}

export const updateLockerSize = size => {
  return { type: UPDATE_LOCKER_SIZE, payload: size }
}

export const getParcels = type => async dispatch => {
  const { storeId } = store.getState().user
  try {
    const res = await api.parcel.list(storeId, type)
    return dispatch({ type: GET_PARCELS, payload: res.data.parcels })
  } catch (error) {
    return handleServerError(error)
  }
}
export const getParcel = parcelId => async dispatch => {
  try {
    const res = await api.parcel.get(parcelId)
    return dispatch({ type: GET_PARCEL, payload: res.data.parcel })
  } catch (error) {
    return handleServerError(error)
  }
}

export const cancelParcel = parcelId => async dispatch => {
  try {
    await api.parcel.cancel(parcelId)
    return dispatch({ type: CANCEL_PARCEL })
  } catch (error) {
    return handleServerError(error)
  }
}

export const createParcel = () => async (dispatch, getState) => {
  const { current } = getState().parcel
  try {
    const res = await api.parcel.create(current)
    dispatch({ type: CREATE_PARCEL })
    return res.data.parcelId
  } catch (error) {
    return handleServerError(error)
  }
}

export const resetCurrentParcel = () => {
  return { type: RESET_CURRENT_PARCEL }
}
